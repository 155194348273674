<template>
  <v-container fluid style="height: 100%">
    <languages />

    <div
      :class="{
        'd-flex justify-center': $vuetify.breakpoint.smAndDown,
        'd-flex justify-start': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <img class="logo-img ma-2" alt="GetCollective" :src="blackLogo" />
    </div>

    <v-row class="align-center">
      <v-col xs="12" sm="8" offset-sm="2" class="text-center">
        <h2
          class="title_dark_blue--text"
        >
          {{ headerLine1 }} <br />
          {{ headerLine2 }}
        </h2>

        <div class="public-form">
          <slot name="form"></slot>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="displayTermsOfUse" class="justify-center align-end">
      <v-col xs="12" sm="6" md="4" lg="3" class="text-center">
        <span class="caption">
          {{ $t("misc.cgu1") }}
          <a target="_blank" :href="termsLink">{{ $t("misc.cgu2") }}</a>
          {{ $t("misc.cgu3") }}
          <a target="_blank" :href="privacyLink">{{ $t("misc.cgu4") }}</a>
          {{ $t("misc.cgu5") }}
        </span>
        <br />
        <span class="caption">
          {{ $t("misc.copyright") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import languages from "./languages";

export default {
  name: "public_layout",
  components: { languages },
  props: {
    headerLine1: {
      type: String,
      required: false,
      default: "",
    },
    headerLine2: {
      type: String,
      required: false,
      default: "",
    },
    displayTermsOfUse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      blackLogo: "../../images/logo_full_black.png",
    };
  },
  computed: {
    termsLink() {
      if (this.$i18n.locale === "fr")
        return "https://www.getcollective.com/fr/terms"
      return "https://www.getcollective.com/en/terms"
    },
    privacyLink() {
      if (this.$i18n.locale === "fr")
        return "https://www.getcollective.com/fr/privacy"
      return "https://www.getcollective.com/en/privacy"
    }
  },
};
</script>

<style scoped>
.public-form {
  max-width: 410px;
  margin: auto;
}

.logo-img {
  z-index: 2;
  padding-top: 24px;
  padding-left: 24px;
}

.logo {
  justify-content: center;
}
</style>
