<template>
  <public-layout :headerLine1="$t('confirmEmail.headerLine')">
    <template v-slot:form>
        <div class="pa-1">
          <div>
            <span class="caption">
              {{ $t("misc.thanksForSignin") }}
            </span>
          </div>
          <br />
          <span class="text--primary caption">
            {{ $t('confirmEmail.plsConfirm') }}

            <v-btn
              block
              color="button_blue"
              elevation="0"
              large
              class="my-4 white--text"
              @click="$auth.loginWithRedirect()"
            >
              <b>{{ $t("confirmEmail.emailConfirmed") }}</b>
              <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </span>
        </div>
      </div>
    </template>
  </public-layout>
</template>

<script>
import publicLayout from "./public_layout.vue";

const State = Object.freeze({
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
});

export default {
  name: "confirm_email",
  components: { publicLayout },
  data() {
    return {
      State,
    };
  },
  computed: {
  },
  methods: {

  },
};
</script>
