<template>
  <v-layout row class="languages" ma-2 style="z-index: 999">
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon mx-4>
          <flag :iso="currentLang.iso" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="lang in Object.keys(languages)"
          :key="lang"
          @click="setLanguage(lang)"
        >
          <v-list-item-icon class="mr-2">
            <flag :iso="languages[lang].iso" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="languages[lang].text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          outlined
          style="border: none"
          @click="$auth.logout"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>

      <span> {{ $t("userProfile.logOut") }}</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
//import flag from 'vue-flag-icon'

import en_mess from "@/locales/en";
import fr_mess from "@/locales/fr";

export default {
  name: "languages",
  //components: { flag },
  computed: {
    languages() {
      return {
        en: {
          label: "en",
          messages: en_mess,
          iso: "gb",
          text: "English",
        },
        fr: {
          label: "fr",
          messages: fr_mess,
          iso: "fr",
          text: "Français",
        },
      };
    },
    currentLang() {
      return this.languages[localStorage.default_locale];
    },
  },
  methods: {
    setLanguage(lang) {
      let label = this.languages[lang].label;
      localStorage.default_locale = label;
      /* TODO. try not reload the page and hot reload locale */
      //this.$i18n.setLocaleMessage(label, this.languages[lang].messages);
      this.$router.go();
    },
  },
};
</script>
<style scoped>
.languages {
  position: absolute;
  right: 24px;
  cursor: pointer;
}
</style>
